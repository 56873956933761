// import cache from './cache';


// TODO: look into this
// https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort


class FetchError extends Error {
  constructor(url, response) {
    super(response);
    this.url = url;
    this.response = response;
  }
}


class OPError extends Error {
  constructor(url, response, content) {
    super(content.err);
    this.url = url;
    this.response = response;
    this.name = content.err;
    this.msg = content.msg;
  }
}


/**
 * Make a remote function call to an op-dev service
 * endpoint: the path of the API we are calling (like 'usage/engagement/users') - should not start with a '/'
 * data: an object containing the data to pass to the API. Keys whose value is undefined will not be included in the
 *   request.
 */
async function opDevFetch(url, authToken=undefined, data=undefined) {
  const options = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-store',
    headers: {},
    body: data && JSON.stringify(data)
  };
  if (options.body) {
    options.headers['Content-Type'] = 'application/json';
  }
  if (authToken) {
    options.headers['Authorization'] = `Bearer ${authToken}`;
  }

  const response = await fetch(encodeURI(url), options);
  // TODO: replay the request if we got an authorization error and our token is expired or missing
  // if (response.status === 401) {
  //   
  // }
  if (response.ok) {
    return await response.json();
  }

  let content;
  try {
    content = await response.json();
  } catch (e) {
    // Could not correctly deserialize JSON from the response
    throw new FetchError(url, response);
  }

  // If we were able to deserialize JSON, then this response should abide by the op-service error format
  if (content.hasOwnProperty('err') && content.hasOwnProperty('msg')) {
    throw new OPError(url, response, content);
  }

  // Last resort (should be unlikely)
  throw new FetchError(url, response);
};

export { opDevFetch, FetchError, OPError };
