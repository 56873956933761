<template>
  <div>
    <v-dialog :value="showDialog" width="500" persistent>
      <v-card>
        <v-card-title>
          <span>Sign In</span>
        </v-card-title>
        <template v-if="isHandler">
          <v-card-text>
            <status-indicator :status="signInStatus" />
              <span class="error--text ml-2" v-if="signInStatus.error">
                Could not sign in - please <a @click="$router.push({ path: '/' })">try again</a>
              </span>
          </v-card-text>
        </template>
        <template v-else>
          <v-card-text>
            <v-form @submit.prevent="sendSignInToken">
              <v-text-field v-model="email" label="Email address" name="email" autocomplete="email" required />
            </v-form>
            <div>You will receive an email containing a sign-in link. Make sure you open that link in this browser.</div>
            <div class="text-primary" v-if="sent"><br/>Email sent</div>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="sendSignInToken">Submit</v-btn>
            <status-indicator :status="sendSignInTokenStatus" />
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>

    <slot v-if="!showDialog" />
  </div>
</template>

<script>
import StatusIndicator from '@/components/StatusIndicator.vue';
import opDevCall from '@/mixins/op-dev-call';
import AsyncStatus from '@/utils/async-status';
import global from '@/utils/global';
import jwtDecode from 'jwt-decode';


export default {
  name: 'sign-in-lock',
  components: {
    StatusIndicator
  },
  mixins: [ opDevCall ],
  data() {
    return {
      global,
      sendSignInTokenStatus: new AsyncStatus(),
      signInStatus: new AsyncStatus(),
      email: null,
      sent: null
    };
  },
  computed: {
    isHandler() {
      return this.$route.name === 'handle-sign-in';
    },
    showDialog() {
      return !this.global.profile || this.isHandler;
    }
  },
  watch: {
   '$route.params.signInToken': {
      immediate: true,
      handler(signInToken) {
        if (!signInToken) {
          return;
        }

        // Someone is trying to sign in with new credentials - let's make sure their old credentials are gone to avoid
        // confusion
        this.global.token = null;
        localStorage.removeItem('global.token');
        this.global.profile = null;

        this.signInStatus.track(async () => {
          const token = await this.call('op-dev', 'get-jwt', { token: signInToken });
          this.$nextTick(() => {
            this.global.token = token;
            localStorage.setItem('global.token', JSON.stringify(token));
            this.$router.push({ path: '/' });
          });
        });
      }
    },
    'global.token': {
      immediate: true,
      handler(value, oldValue) {
        try {
          const profile = jwtDecode(this.global.token);
          if (!profile.exp || Date.now() >= profile.exp * 1000) {
            // Token is expired
            return;
          }
          this.global.profile = profile;
        } catch (e) {
          // There was an issue parsing the JWT
          return;
        }
      }
    }
  },
  created() {
    let previous = localStorage.getItem('global.token');
    this.pollGlobalLocalStorageToken = setInterval(
      () => {
        const value = localStorage.getItem('global.token');
        // Note that we don't deserialize our stored values to simplify comparison
        if (previous !== value) {
          try {
            // Note that this test also covers undefined, not just null
            const deserialized = (value == null) ? undefined : JSON.parse(value);
            previous = value;
            this.global.token = deserialized;
            // We're responding to a change from localStorage, so no need to set the value in turn
          } catch (e) {
            // Our localStorage got corrupted somehow - all global props should be serialized
            localStorage.removeItem('global.token');
            console.warn("Removed invalid 'global.token' property from localStorage");
          }
        }
      },
      2000
    );
  },
  destroyed() {
    clearInterval(this.pollGlobalLocalStorageToken);
  },
  methods: {
    sendSignInToken() {
      this.sendSignInTokenStatus.track(async () => {
        await this.call('op-dev', 'send-sign-in-token', { email: this.email });
        this.sent = this.email;
      });
    }
  }
};
</script>
