import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import VueQuill from 'vue-quill';

Vue.config.productionTip = false;
Vue.use(VueQuill);

// TODO: move this to a plugin probably?
import moment from 'moment';
Vue.filter('moment', (m, fmt) => m && (fmt ? moment(m).format(fmt) : moment(m).fromNow()));

new Vue({ vuetify, router, render: h => h(App) }).$mount('#app');
