<template>
  <v-app>

    <sign-in-lock>

      <v-navigation-drawer v-model="drawer" app>
        <v-list dense>
          <template v-for="navItem of navItems">
            <v-divider v-if="!navItem.name" />
            <v-list-item
              v-else link
              :disabled="navItem.team && !global.teamId"
              @click="$router.push({ name: navItem.route }).catch(e => {})">
              <v-list-item-action>
                <v-icon>{{navItem.icon}}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{navItem.name}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item link @click="signOut">
            <v-list-item-action>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Sign out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar app>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title class="headline text-uppercase">
          <status-indicator :status="loadTeamStatus" show-with-no-result>{{global.team && global.team.name}}</status-indicator>
        </v-toolbar-title>
      </v-app-bar>

      <v-main>
        <router-view />
      </v-main>

    </sign-in-lock>

  </v-app>
</template>

<style lang="scss">
@import 'quill.snow.css';

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Marginally better styling for definition lists */
/* TODO: tie these in to Vuetify style variables */
dl {
  dt {
    float: left;
    margin-right: 8px;
    user-select: none;
    font-size: 12px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.54);
  }

  dd {
    &:not(:last-child) {
      margin-bottom: 12px;
    }

    // Ensure that empty 'dd' elements do not cause the structure to collapse
    &:empty:after {
      content: '\A';
      white-space: pre;
    }
  }

  dt,
  dd {
    line-height: 18px;
  }
}
dl.dense {
  dd {
    margin-bottom: 6px;
  }
}


/* Fix an issue that causes list icons not to be centered */
.v-list-item__icon {
  align-self: unset !important;
}

/* Fix an issue where action items break dense lists */
.v-list--dense .v-list-item__action {
  margin-top: 0;
  margin-bottom: 0;
}

.v-application {
  code {
    &.full-width {
      width: 100%;
    }

    &.multi-line {
      /* For displaying code with multiple lines (wrap each line in a div) */
      div {
        padding-left: 24px;
        text-indent: -24px;

        &:before, &:after {
          content: ' ';
        }
      }

      &:before, &:after {
        content: unset !important;
      }
    }
  }

  .overline.short {
    line-height: 1rem;
  }
}
</style>

<script>
import SignInLock from '@/components/SignInLock.vue';
import StatusIndicator from '@/components/StatusIndicator.vue';
import { OPError } from '@/utils/op-dev-fetch';
import opDevCall from '@/mixins/op-dev-call';
import global from '@/utils/global';
import AsyncStatus from '@/utils/async-status';
import jwtDecode from 'jwt-decode';


const navItems = [
  { name: 'To Do', route: 'to-do', icon: 'mdi-text-box-check-outline', team: true },
  // { name: 'Shared Files', route: 'shared-files', icon: 'mdi-file-cloud', team: true },
  { name: 'Domains', route: 'domains', icon: 'mdi-web', team: true },
  { name: 'Packages', route: 'packages', icon: 'mdi-package-variant', team: true },
  // { name: 'Alarms', route: 'alarms', icon: 'mdi-alert', team: true },
  // {},
  // { name: 'Services', route: 'services', icon: 'mdi-cloud', team: true },
  // { name: 'Other Tools', route: 'tools', icon: 'mdi-tools', team: true },
  // {},
  // { name: 'AWS', route: 'aws', icon: 'mdi-aws', team: true },
  // { name: 'Google Cloud', route: 'google-cloud', icon: 'mdi-google-cloud', team: true },
  { name: 'Dependencies', route: 'dependencies', icon: 'mdi-database', team: true },

  // TODO: only show this route if we have the 'OP issue tracker' as a dependency
  { name: 'Issues', route: 'issues', icon: 'mdi-alert', team: true },

  // { name: 'Storage', route: 'stores', icon: 'mdi-database', team: true },
  // { name: 'SMS', route: 'sms', icon: 'mdi-cellphone-message', team: true },
  // { name: 'Email', route: 'transactional-email', icon: 'mdi-email', team: true },
  // { name: 'Email Marketing', route: 'marketing-email', icon: 'mdi-email-plus', team: true },
  { name: 'OP Service Explorer', route: 'explore-chooser', icon: 'mdi-cloud-search' },
  { name: 'Teams', route: 'teams', icon: 'mdi-account-group' },
  { name: 'Account', route: 'account', icon: 'mdi-account' }
];


export default {
  name: 'app',
  components: {
    SignInLock,
    StatusIndicator
  },
  mixins: [ opDevCall ],
  data() {
    return {
      global,
      loadTeamStatus: new AsyncStatus(),
      drawer: null,
      navItems
    };
  },
  methods: {
    signOut() {
      this.global.token = null;
      localStorage.removeItem('global.token');
      this.global.profile = null;
    }
  },
  watch: {
    'global.profile'(value) {
      if (!value) {
        // We're not signed in (nothing to do)
      } else if (this.global.team) {
        // We already have the team set, so nothing to do
      } else if (this.global.teamId) {
        // Presumably we just signed on and loaded the 'teamId' from localStorage
        this.loadTeamStatus.track(async () => {
          try {
            const team = await this.call('op-dev', 'get-team', { team_id: this.global.teamId });
            this.$nextTick(() => { this.global.team = team; });
          } catch (e) {
            // We've either deleted this team, or our local storage became corrupted. Because those are unusual
            // cases, I'd prefer to direct to the teams page, rather than automatically switching to a different team
            // (even if the user has only one)
            localStorage.removeItem('global.teamId');
            console.warn("Removed invalid 'global.teamId' property from localStorage");
            this.$nextTick(() => { this.global.teamId = null; });
          }
        });
      } else {
        // We don't have a team stored, if the user has only one, we can set it automatically
        this.loadTeamStatus.track(async () => {
          const teams = await this.call('op-dev', 'list-teams');
          if (teams.length == 1) {
            // This user has exactly one team - select it
            localStorage.setItem('global.teamId', JSON.stringify(teams[0][0]));
            this.$nextTick(() => { this.global.teamId = teams[0][0]; this.global.team = teams[0][1]; });
          } else {
            // Redirect to the teams page so the user can select a team
            this.$router.push({ name: 'teams' });
          }
        });
      }
    }
  }
}
</script>
