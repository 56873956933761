<!--
Use in conjunction with the 'AsyncStatus' class to gate asynchronous content with a busy indicator, or an error if one
is thrown.

Example usage:

// Template
<template>
  <div>
    <button @click="load">Test</button>
    <status-indicator :status="status">{{message}}</status-indicator>
  </div>
</template>

// Component
{
  name: 'test',
  components: { StatusIndicator },
  data() {
    return {
      message: undefined,
      status: new AsyncStatus()
    }
  },
  methods: {
    load() {
      this.status.track(async () => {
        await new Promise(resolve => setTimeout(resolve, 1000));
        if (Math.random() < 0.5) {
          throw new Error('Random error');
        }
        this.message = 'Hello world';
      });
    }
  }
}
-->

<template>
  <div class="status-indicator-container">
    <!-- TODO: you may want to show an indicator here if there are multiple calls in progress -->
    <v-icon v-if="status.isBusy()" :small="small" class="spinner">mdi-reload</v-icon>
    <v-tooltip top v-else-if="error">
      <template v-slot:activator="{ on }">
        <v-icon color="error" v-on="on">mdi-reload-alert</v-icon>
      </template>
      <span>{{error}}</span>
    </v-tooltip>
    <slot v-else-if="showWithNoResult || hasResult" />
  </div>
</template>

<style scoped>
.status-indicator-container {
  display: inline;
}

.spinner {
  animation: spin .7s infinite linear;
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}
</style>

<script>
export default {
  name: 'status-indicator',
  props: {
    status: Object,
    small: false,
    // Set this property to show to the contents of the inner slot even before the tracked function runs
    showWithNoResult: Boolean
  },
  computed: {
    isBusy() {
      return this.status.isBusy;
    },
    hasResult() {
      return this.status.hasResult;
    },
    error() {
      return this.status.error;
    }
  }
};
</script>
