/**
 * Use this class in conjunction with the status indicator component to show a status indicator (see that component for
 * example usage). Note that this function also handles the case when multiple calls have been made to the wrapped
 * function - it stays in the 'busy' state until the last call returns.
 */
class AsyncStatus {

  constructor() {
    this.busyCount = 0;
    this.hasResult = false;
    this.error = null;
  }

  // We've deliberately wrapped this up as a method instead of a getter to support the typical use case within a Vue
  // component - component can't detect changes in a getter.
  isBusy() {
    return this.busyCount > 0;
  }

  track(fn) {
    this.busyCount++;
    const parent = this;
    return fn.apply(this, arguments)
      .then(data => {
        parent.error = null;
        return data;
      })
      .catch(e => {
        parent.error = e;
      })
      .finally(() => {
        parent.busyCount--;
        parent.hasResult = true;
      });
  }
}


export default AsyncStatus;
