import { opDevFetch } from '@/utils/op-dev-fetch';
import global from '@/utils/global';


export default {
  methods: {
    async call(service, fn, data=undefined) {
      const url = process.env.OP_DEV_CONFIG[service].replace(/\/$/, '') + '/' + fn.replace(/^\//, '');
      try {
        return await opDevFetch(url, global.token, data);
      } catch (e) {
        if (e.name === 'ExpiredToken') {
          global.token = null;
          localStorage.removeItem('global.token');
          global.profile = null;
          throw e;
        }
        throw e;
      }
    }
  }
};
