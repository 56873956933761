/*
A store for keeping track of global variables within an application. This is a super-lightweight version of Vuex, which
has a complex interface that I don't like. Setting any properties on the 'state' will make those properties immediately
visible to any of the children who have included it in thir 'data' functions.

Usage:
// In this file:
export default {
  message: null
};


// In a component:
import global from '@/utils/global';

new Vue({
  data() {
    global
  },
  methods: {
    // If you want to update any global properties, you must do it by accessing 'this', not through the 'global' object
    // directly.
    this.global.message = 'Hello world';
  }
});

If you want to do any initialization of this state, I suggest doing it in the 'App' component in the 'created' function.
*/


const LOCAL_STORAGE_SYNC = {};


// WARNING: This strategy breaks vue reactivity, which is why it is not used. Since the 'App' component manages
// listening to the global changes, it also syncs them with localStorage if needed.
function buildSyncState(state) {
  const newState = {}; // The new object that uses getters / setters for localStorage properties
  const syncState = {}; // Where we store the actual values synced with localStorage that back our getters / setters
  for (const prop in state) {
    if (!state.hasOwnProperty(prop)) {
      continue;
    }

    if (state[prop] !== LOCAL_STORAGE_SYNC) {
      newState[prop] = state[prop];
      continue;
    }

    const localStorageKey = 'global.' + prop;

    const item = localStorage.getItem(localStorageKey);
    syncState[prop] = item && JSON.parse(item);
    
    // This strategy breaks vue reactivity with the property
    Object.defineProperty(
      newState,
      prop,
      {
        get() {
          return syncState[prop];
        },
        set(value) {
          syncState[prop] = value;
          const item = JSON.stringify(value);
          localStorage.setItem(localStorageKey, item);
        }
      }
    );
  }

  return newState;
}


function getGlobalPropFromLocalStorage(prop) {
  const value = localStorage.getItem('global.' + prop);
  try {
    return (value == null) ? undefined : JSON.parse(value);
  } catch (e) {
    // Non-JSON item in localStorage - not allowed
    localStorage.removeItem('global.' + prop);
  }
}


// All global propeties must be explicitly defined here - you can't add / remove them on the fly
export default {
  teamId: getGlobalPropFromLocalStorage('teamId'),
  team: null,
  token: getGlobalPropFromLocalStorage('token'),
  profile: null
};
