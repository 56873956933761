import global from '@/utils/global';
import Vue from 'vue';
import VueRouter from 'vue-router';

// Avoid logging 'NavigationDuplicated' exceptions - note that this hides other potential exceptions
const _push = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return _push.call(this, location).catch(e => null);
}

Vue.use(VueRouter);

function load(type, name) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/${type}/${name}.vue`);
}

const routes = [
  {
    path: '/sign-in',
    redirect: '/'
  },
  {
    path: '/sign-in/:signInToken',
    name: 'handle-sign-in'
  },
  {
    path: '/to-do/:taskId?',
    name: 'to-do',
    props: true,
    component: load('views', 'ToDo')
  },
  {
    path: '/tools-and-services',
    name: 'tools',
    component: load('views', 'Tools')
  },
  {
    path: '/domains',
    name: 'domains',
    component: load('views', 'Domains'),
    children: [
      {
        path: ':domainName',
        name: 'domain',
        props: true,
        component: load('components', 'DomainDetails')
      }
    ]
  },
  {
    path: '/packages',
    name: 'packages',
    component: load('views', 'Packages'),
    children: [
      {
        path: ':pkgId',
        name: 'package',
        props: true,
        component: load('components', 'PackageDetails')
      }
    ]
  },
  {
    path: '/dependencies',
    name: 'dependencies',
    component: load('views', 'Dependencies'),
    children: [
      {
        path: ':dependencyId',
        name: 'dependency',
        props: true,
        component: load('components', 'DependencyDetails')
      }
    ]
  },
  {
    path: '/account',
    name: 'account',
    component: load('views', 'Account')
  },
  {
    path: '/teams',
    name: 'teams',
    component: load('views', 'Teams'),
    children: [
      {
        path: ':teamId',
        name: 'team',
        props: true,
        component: load('components', 'TeamDetails')
      }
    ]
  },
  {
    path: '/explore',
    name: 'explore-chooser',
    component: load('views', 'ExploreChooser')
  },
  {
    path: '/explore/:domain/:route?',
    name: 'explore',
    props: true,
    component: load('views', 'Explore')
  },
  {
    path: '/issues/:issueId?',
    name: 'issues',
    props: true,
    component: load('views', 'Issues')
  }
];

export default new VueRouter({ mode: 'history', routes });
